import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  useTheme,
  Button,
  Typography,
  Popper,
  Paper,
  darken,
  useMediaQuery,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  Grid,
  Box,
  IconButton,
  alpha,
} from "@mui/material";
import { Diversity3 } from "@mui/icons-material";
import { Close } from "@mui/icons-material";
import { useTranslation } from "react-i18next";
import { openModal, closeModal } from "../../features/slices/modalSlice";

import Image from "../common/Image";

import { selectCurrentToken } from "../../features/slices/authSlice";

import {
  useLazyGetGameProviderQuery,
  useLazyGetLaunchGameQuery,
  useGetGameCategoryQuery,
} from "../../features/api/gameApiSlice";
import Backdrop from "../common/Backdrop";

import HomeIcon from "../../icons/header/HomeIcon";
import PromotionIcon from "../../icons/header/PromotionIcon";
import VipIcon from "../../icons/header/VipIcon";
import CasinoIcon from "../../icons/header/CasinoIcon";
import FishingIcon from "../../icons/header/FishingIcon";
import SlotIcon from "../../icons/header/SlotIcon";
import SportIcon from "../../icons/header/SportIcon";
import SabungIcon from "../../icons/header/SabungIcon";
import * as Constant from "../../features/constant";

const DesktopHeaderItems = ({ label, code, selectedPath, setSelectedPath }) => {
  const theme = useTheme();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const token = useSelector(selectCurrentToken);
  const mobileView = useMediaQuery(theme.breakpoints.down("sm"));
  const [anchorEl, setAnchorEl] = useState(null);
  const [popperOpen, setPopperOpen] = useState(false);

  const {
    data: gameCategory,
    isFetching: isGameCategoryFetching,
    isLoading: isGameCategoryLoading,
    isError: isGameCategoryError,
    isSuccess: isGameCategorySuccess,
    error: gameCategoryError,
  } = useGetGameCategoryQuery({ prefetch: true });

  const [
    getGameProvider,
    {
      data: gameProvider,
      isFetching: isGameProviderFetching,
      isLoading: isGameProviderLoading,
      isError: isGameProviderError,
      isSuccess: isGameProviderSuccess,
      error: gameProviderError,
    },
  ] = useLazyGetGameProviderQuery({ category: "" }, { prefetch: true });

  const [
    getLaunchGame,
    {
      data: launchGame,
      isFetching: isLaunchGameFetching,
      isLoading: isLaunchGameLoading,
      isError: isLaunchGameError,
      isSuccess: isLaunchGameSuccess,
      error: launchGameError,
    },
  ] = useLazyGetLaunchGameQuery({ prefetch: true });

  useEffect(() => {
    if (!isLaunchGameLoading && !isLaunchGameFetching && isLaunchGameSuccess) {
      if (launchGame?.result?.gameType === "WEB") {
        if (launchGame?.result?.iframeSupported == true) {
          window.location.href = process.env.REACT_APP_GAME_URL_IFRAME + launchGame.result.gameUrl;
        }
        else {
          window.open(launchGame.result.gameUrl, "_blank");
        }
      }
    }
  }, [isLaunchGameLoading, isLaunchGameFetching, isLaunchGameSuccess]);

  useEffect(() => {
    setAnchorEl(null);
    setPopperOpen(false);
  }, []);

  const handleAnchorEnter = (event, code) => {
    getGameProvider({ category: code });
    setAnchorEl(event.currentTarget);
    setPopperOpen(true);
  };

  const handleAnchorLeave = () => {
    setAnchorEl(null);
    setPopperOpen(false);
  };

  const handlePopperEnter = () => {
    setPopperOpen(true);
  };

  const handlePopperLeave = () => {
    setPopperOpen(false);
  };

  if (isLaunchGameFetching || isLaunchGameLoading) {
    return <Backdrop />;
  }

  return (
    <>
      <Button
        variant="outlined"
        sx={{
          color: selectedPath ? theme.palette.tertiary.main : "white",
          pt: 0,
          ":hover": {
            color: theme.palette.tertiary.main,
            borderColor: "transparent"
            // borderBottom: selectedPath && 3,
          },
          // borderBottom: selectedPath && 3,
          borderRadius: 0,
          // display: "flex",
          // flexDirection: "column",
          borderColor: "transparent",
          pl: 0
          // justifyContent: "center",
          // alignItems: "center",
          // alignSelf: "center",
        }}
        disableRipple
        onClick={() => {

          const affiliateUrl = "https://www.aff.winbagh8.com/";

          if (label === t("header.affiliate")) {
            window.open(affiliateUrl, "_blank");
            window.fbq('trackCustom', 'affiliate_' + label);
          }
          else {
            window.fbq('trackCustom', 'category_' + label);
            navigate(
              label === t("header.home")
                ? Constant.getPath("/")
                : code?.toLowerCase() === "slot"
                  ? Constant.getPath("/game/slot")
                  : code?.toLowerCase() === "live_casino"
                    ? Constant.getPath("/game/live-casino")
                    : code?.toLowerCase() === "sportbook"
                      ? Constant.getPath("/game/sportbook")
                      : code?.toLowerCase() === "fish_hunter"
                        ? Constant.getPath("/game/fish-hunter")
                        : code?.toLowerCase() === "instantwin"
                          ? Constant.getPath("/game/instantwin")
                          : code?.toLowerCase() === "crash"
                            ? Constant.getPath("/game/crash")
                            : code?.toLowerCase() === "card"
                              ? Constant.getPath("/game/card")
                              : code?.toLowerCase() === "cricket"
                                ? Constant.getPath("/game/cricket")
                                : code?.toLowerCase() === "esports"
                                  ? Constant.getPath("/game/esports")
                                  : code?.toLowerCase() === "cockfight"
                                    ? Constant.getPath("/game/cockfight")
                                    : label === t("header.promotion")
                                      ? Constant.getPath("/promotion")
                                      : label === t("header.vip")
                                        ? Constant.getPath("/vip")
                                        // : label === t("header.affiliate")
                                        // ? Constant.getPath("/affiliate")
                                        : null
            );
            setSelectedPath(
              label === t("header.home")
                ? Constant.getPath("/")
                : code?.toLowerCase() === "slot"
                  ? Constant.getPath("/game/slot")
                  : code?.toLowerCase() === "live_casino"
                    ? Constant.getPath("/game/live-casino")
                    : code?.toLowerCase() === "sportbook"
                      ? Constant.getPath("/game/sportbook")
                      : code?.toLowerCase() === "fish_hunter"
                        ? Constant.getPath("/game/fish-hunter")
                        : code?.toLowerCase() === "instantwin"
                          ? Constant.getPath("/game/instantwin")
                          : code?.toLowerCase() === "crash"
                            ? Constant.getPath("/game/crash")
                            : code?.toLowerCase() === "card"
                              ? Constant.getPath("/game/card")
                              : code?.toLowerCase() === "cricket"
                                ? Constant.getPath("/game/cricket")
                                : code?.toLowerCase() === "esports"
                                  ? Constant.getPath("/game/esports")
                                  : code?.toLowerCase() === "cockfight"
                                    ? Constant.getPath("/game/cockfight")
                                    : label === t("header.promotion")
                                      ? Constant.getPath("/promotion")
                                      : label === t("header.vip")
                                        ? Constant.getPath("/vip")
                                        // : label === t("header.affiliate")
                                        // ? Constant.getPath("/affiliate")
                                        : null
            );
          }
        }
        }
        onMouseEnter={(event) => handleAnchorEnter(event, code)}
        onMouseLeave={handleAnchorLeave}
      >
        {label === t("header.home") ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/HOME.png"}
          />
        ) : code?.toLowerCase() === "cricket" ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/CRICKET_2.png"}
          />
        ) : code?.toLowerCase() === "card" ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/CARDGAMES_2.png"}
          />
        ) : code?.toLowerCase() === "esports" ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/ESPORTS_2.png"}
          />
        ) : code?.toLowerCase() === "cockfight" ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/COCKFIGHT_2.png"}
          />
        ) : code?.toLowerCase() === "slot" ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/SLOT_2.png"}
          />
        ) : code?.toLowerCase() === "live_casino" ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/LIVE_CASINO_2.png"}
          />
        ) : code?.toLowerCase() === "sportbook" ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/SPORT_2.png"}
          />
        ) : code?.toLowerCase() === "fish_hunter" ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/FISHING_2.png"}
          />
        ) : (code?.toLowerCase() === "instantwin" || code?.toLowerCase() === "crash") ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/INSTANTWIN_2.png"}
          />
        ) : label === t("header.promotion") ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/PROMOTION.png"}
          />
        ) : label === t("header.vip") ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/VIP.png"}
          />
        ) : label === t("header.affiliate") ? (
          <Image
            width={25}
            height={25}
            src={process.env.REACT_APP_HEADER_LOGO + "/AFFILIATE.png"}
          />
        ) : null}
        <Typography
          variant="sublabel1"
          textAlign="center"
          fontWeight={600}
          marginLeft="5px"
        >
          {label}
        </Typography>
      </Button>
      {(code === "SLOT" ||
        code === "LIVE_CASINO" ||
        code === "SPORTBOOK" ||
        code === "CRICKET" ||
        code === "CARD" ||
        code === "ESPORTS" ||
        code === "COCKFIGHT" ||
        code === "INSTANTWIN" ||
        code === "CRASH" ||
        code === "FISH_HUNTER") && (
          <Popper
            id="mouse-over-popover"
            open={popperOpen}
            anchorEl={anchorEl}
            placement="bottom"
            sx={{
              zIndex: 1,
              width: "100%",
            }}
          >
            <Paper
              elevation={4}
              sx={{
                p: 2,
                backgroundColor: alpha("#000000", 0.9),
                width: "100%",
              }}
              onMouseEnter={handlePopperEnter}
              onMouseLeave={handlePopperLeave}
            >
              {!isGameProviderFetching &&
                !isGameProviderLoading &&
                isGameProviderSuccess && (
                  <>
                    <Grid
                      container
                      spacing={0}
                      sx={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                    >
                      {gameProvider?.result?.map((provider, idx) => (
                        // <Grid
                        //   key={idx}
                        //   item
                        //   xs={1}
                        //   sx={{
                        //     cursor: "pointer",
                        //   }}
                        // >
                        <Box
                          m={1}
                          onClick={() => {
                            let hasGameList = gameCategory?.result?.find(
                              (category) => category.code === code
                            )?.hasGameList;
                            if (hasGameList) {
                              window.fbq('trackCustom', 'click_' + provider?.name);

                              navigate(Constant.getPath(`/game/${code.toLowerCase().replaceAll("_", "-")}/${provider.name.replaceAll(" ", "-").toLowerCase()}`), {
                                state: {
                                  providerId: provider?.id,
                                },
                                replace: true,
                              });
                            } else {
                              if (!token) {
                                dispatch(
                                  openModal({
                                    title: t("dialog.proceedtologin"),
                                    button: [
                                      {
                                        label: t("dialog.login"),
                                        onSubmit: () => dispatch(closeModal()),
                                      },
                                    ],
                                  })
                                );
                              } else {
                                getLaunchGame({
                                  id: provider?.id,
                                  type: provider?.gameType,
                                  category: code,
                                  isMobile: mobileView
                                });
                                window.fbq('trackCustom', 'click_' + provider?.name);
                              }
                            }
                            handlePopperLeave();
                          }}
                        >
                          <Image src={provider?.navIconUrl} />
                        </Box>
                        // </Grid>
                      ))}
                    </Grid>
                  </>
                )}
            </Paper>
          </Popper>
        )}
    </>
  );
};

export default DesktopHeaderItems;
