import React, { useEffect } from "react";
import * as yup from "yup";
import { useDispatch } from "react-redux";
import {Container, Typography, Button, Box, useTheme} from "@mui/material";
import FormTextField from "../form/FormTextField";
import FormSelect from "../form/FormSelect";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { setAlert } from "../../features/slices/alertSlice";
import { useTranslation } from "react-i18next";
import {useGetBankAccountsQuery, useGetProfileQuery} from "../../features/api/userApiSlice";
import * as Constant from "../../features/constant";
import {useNavigate} from "react-router-dom";
import {DataGrid} from "@mui/x-data-grid";
import format from "date-fns/format";

const BankAccount = ({ usePlayerBanksMutation }) => {
  const dispatch = useDispatch();
  const { t } = useTranslation();
  const navigate = useNavigate();
  const theme = useTheme();

  const {
    data: bankDetails,
    isLoading: isBankDetailsLoading,
    isError: isBankDetailsError,
    isSuccess: isBankDetailsSuccess,
    error: bankDetailsError,
  } = useGetBankAccountsQuery();

  const {
    data: user,
    isFetching: isUserFetching,
    isLoading: isUserLoading,
  } = useGetProfileQuery({ prefetch: true });

  const passwordSchema = yup.object().shape({
    accountname: yup.string().required(t("validation.bankaccname")),
    bankcode: yup.string().required(t("validation.bankaccname")),
    bankaccount: yup
      .string()
      .required(t("validation.bankaccno"))
      .matches(/^[0-9]+$/, t("validation.bankaccnoonlynumbers")) // Only numbers allowed
  });
  

  const [playerBanks, { data, isLoading, isError, isSuccess, error }] =
      usePlayerBanksMutation();

  const { control, handleSubmit, reset } = useForm({
    resolver: yupResolver(passwordSchema),
  });

  const submitBank = async (data) => {

    let newData = {
      bankCode: data?.bankcode,
      bankAccountNumber: data?.bankaccount,
      bankAccountName: data?.accountname,
    };

    await playerBanks(newData).unwrap();
    window.location.reload();
  };

  useEffect(() => {
    if (!isLoading && isSuccess) {
      dispatch(
        setAlert({
          isOpen: true,
          message: t("walletcard.bankaccountpage.successmessage"),
          variant: "success",
        })
      );
      reset();
    }
    if (isError) {
      dispatch(
        setAlert({
          isOpen: true,
          message: error.data.message,
          variant: "error",
        })
      );
    }
  }, [isLoading, isSuccess, isError]);

  return (
    <Container>
      <Typography fontWeight="bold" color="white">
        {t("walletcard.bankAccount")}
      </Typography>
      <Box display="flex" flexDirection="column" >
        <form onSubmit={handleSubmit(submitBank)}>
          <FormTextField
            label={t("walletcard.bankaccountpage.accountname")}
            control={control}
            controllerName="accountname"
            defaultValue={user?.result?.fullname}
            size="small"
            disabled={true}
          />
          <FormSelect
              label={t("walletcard.bankaccountpage.bankname")}
              control={control}
              controllerName="bankcode"
              items={bankDetails?.result?.bankConfigs
                  .map((item) => item.bankCode)}
              size="small"
          />
          <FormTextField
            label={t("walletcard.bankaccountpage.bankaccount")}
            control={control}
            controllerName="bankaccount"
            defaultValue={""}
            size="small"
          />
          <Box alignSelf="center" mb="15px" mt="15px">
            <Button variant="contained" type="submit">
              {t("walletcard.submit")}
            </Button>
          </Box>
        </form>
      </Box>
      <Box sx={{ height: 400, width: "100%", mt: "15px" }}>
        <DataGrid
            sx={{
              '.MuiDataGrid-columnSeparator': {
                display: 'none',
              },
              '&.MuiDataGrid-root': {
                border: 'none',
              },
              "& .MuiDialogContent-root": {
                overflowY: "auto",
                "::-webkit-scrollbar": {
                  width: "12px",
                },
                "::-webkit-scrollbar-track": {
                  background: theme.palette.text.disabled,
                },
                "::-webkit-scrollbar-thumb": {
                  background: theme.palette.background.paper,
                },
              },
              color: "white",
              backgroundColor:theme.palette.primary.main,
            }}
            rows={
                bankDetails?.result?.data.map((content, idx) => {
                  return {
                    ...content,
                    id: idx + 1,
                  };
                }) || []

            }
            columns={[
              {
                field: "id",
                headerName: t("walletcard.transactionpage.id"),
                width: 150,
                editable: false,
              },
              {
                field: "bankAccountName",
                headerName: t("walletcard.bankaccountpage.accountname"),
                width: 150,
                editable: false,
              },
              {
                field: "bankAccountNumber",
                headerName: t("walletcard.bankaccountpage.bankaccount"),
                width: 150,
                editable: false,
              },
              {
                field: "bankCode",
                headerName: t("walletcard.bankaccountpage.bankname"),
                width: 150,
                editable: false,
              }
            ]}
            hideFooter={true}
            rowsPerPageOptions={[5]}
        />
      </Box>
    </Container>
  );
};

export default BankAccount;
